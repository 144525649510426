import React, { useState } from "react";
import Nav from "../components/Nav";
import SectionOne from "../components/SectionOne";
import SectionTwo from "../components/SectionTwo";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import PaymentModal from "../components/PaymentModal";

// markup
const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleOpenModal() {
    setShowModal(true);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sunrise Speech | Home</title>
        <link rel="canonical" href="https://sunrisespeechandswallowing.com" />
        <meta
          name="description"
          content="Sunrise Speech and Swallowing Therapy"
        />
        <meta
          name="keywords"
          content="asheville speech therapy, asheville swallowing therapy, asheville"
        />

        <meta name="author" content="Jay Hoots" />

        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main>
        <Nav openModal={handleOpenModal} />
        {showModal && <PaymentModal closeModal={handleCloseModal} />}
        <SectionOne />
        <SectionTwo />
        <Footer openModal={handleOpenModal} />
      </main>
    </>
  );
};

export default IndexPage;
