import React from "react";

const SectionOne = () => {
  return (
    <>
      <div className="hero flex justify-center items-end">
        <div className="flex justify-center items-center pb-6">
          <div className="bg-sunrise-orange-opaque p-3 w-full md:w-4/5 md:mb-5 text-center">
            <h1 className="my-4 text-3xl text-white font-bold leading-tight">
              Sunrise Speech and Swallowing Therapy
            </h1>
            <p className="leading-normal text-white text-2xl mb-8">
              Where your quality of life is the first priority...
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
