import React from "react";
import TherapistImg from "../images/shelby-bio-pic.jpg";
import pathologyImg from "../images/pathology.jpg";
import ashaImg from "../images/ASHA-CCC-Color.png";
import mdtpImg from "../images/mdtp.png";
import lsvtImg from "../images/lsvt-loud-cert.jpg";

const SectionTwo = () => {
  return (
    <>
      <section className="border-b py-8 bg-cool-gradient">
        <div className="w-full mx-auto sm:max-w-7xl justify-center">
          <h1 className="w-full text-white my-2 text-5xl font-bold leading-tight text-center">
            Meet the Therapist
          </h1>

          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 sm:mt-6">
              <h3 className="text-3xl text-white font-bold leading-none mb-3">
                {`Shelby Bjorlie MA CCC-SLP`}
              </h3>
              <p className="text-gray-100 text-lg leading-8">
                {`Shelby received an undergraduate degree in Psychology and Environmental Studies from St. Andrews Presbyterian College in 1996. She studied Communication Disorders at Appalachian State University and received a masters degree in 2006. Since then she has specialized in the diagnosis and treatment of  adult and geriatric populations with speech-language and swallowing disorders. Shelby is certified to perform FEES (flexible endoscopic evaluation of swallowing). She is a certified MDTP provider through the Florida dysphagia institute. Shelby is certified by the NC board of SLP-A and certified nationally by the American Speech and Hearing Association. `}
              </p>
              <div className="flex flex-col sm:flex-row max-w-xl justify-center">
                <div className="bg-white sm:w-7/12 sm:mr-10 mt-6 sm:h-44 p-4 justify-center flex">
                  <img src={ashaImg} className="w-full " alt="ASHA_CCC" />
                </div>
                <img
                  className="w-full mt-6 sm:w-5/12 sm:h-44"
                  src={mdtpImg}
                  alt="Shelby is an accomplished therapist"
                />
              </div>
              <div className="flex flex-col sm:flex-row max-w-xl justify-center mt-6">
                <img src={lsvtImg} alt="Shelby also has " />
              </div>
            </div>
            <div className="w-full sm:w-1/2 p-6 sm:mt-6 sm:pt-0 md:pt-18">
              <img
                src={TherapistImg}
                alt="Shelbys is an accomplished therapist"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="border-b py-8 w-full bg-white">
        <div className="w-full mx-auto sm:max-w-7xl text-black justify-center">
          <div className="flex w-full flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 sm:mt-6">
              <img src={pathologyImg} alt="sunrise, pathology image" />
            </div>
            <div className="w-full sm:w-1/2 p-6 sm:mt-6">
              <div className="align-middle">
                <h3 className="text-3xl sm:text-right text-gray-800 font-bold leading-none mb-3">
                  {`OUR APPROACH`}
                </h3>
                <p className="text-gray-800 mb-8 sm:text-right text-lg">
                  {`Communication is fundamental to who we are as individuals. It is how we connect and is a basic human right. Eating and drinking is not only needed for survival but an enjoyable and social experience. Changes or loss of communication and swallowing abilities can be devastating. At Sunrise Speech and Swallowing Therapy our goal is to help you regain and maintain these abilities so you can carry on with your life! By spending time with you and your loved ones, we can form a comprehensive plan to help you say and do the things that matter most.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionTwo;
